import { type HydrationOptions } from 'react-dom/client'
import { type ServerOptions } from 'react-dom/server'
import { type ErrorBoundaryOptions } from './with-error-boundary'
import { LoggerOptions, type FragmentRootOptions } from '@vp/ubik-fragment-types'

declare module '@vp/ubik-fragment-types' {
  // using interface merging to add new properties to FragmentRootOptions
  // some of these could be moved to the @vp/ubik-fragment-types
  interface FragmentRootOptions {
    strictMode?: boolean
    loggerOptions?: LoggerOptions
    errorBoundaryOptions?: ErrorBoundaryOptions
    hydrateOptions?: HydrationOptions
    serverOptions?: ServerOptions
  }
}

export const createRootOptions = (
  options: FragmentRootOptions = {}
): FragmentRootOptions => {
  return {
    ...options,
    strictMode: options.strictMode ?? true,
    errorBoundaryOptions: options.errorBoundaryOptions ?? false,
    loggerOptions: {
      serviceName: 'defaultServiceName',
      ...options.loggerOptions,
    },
    hydrateOptions: {
      ...options.hydrateOptions,
    },
    serverOptions: {
      ...options.serverOptions,
    },
  }
}
