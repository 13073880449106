import React from 'react'
import { hydrateRoot, type Root } from 'react-dom/client'
import { SwanProvider } from '@vp/swan'
import { LoggerProvider, StyleContextProvider, UserContextProvider } from '@vp/ubik-context'
import { createUserContext } from './create-user-context'
import { FragmentRootOptions, type UserContext } from '@vp/ubik-fragment-types'
import { createRootOptions, withErrorBoundary, withStrictMode } from '../common'
import { ClientMountOptions } from './deprecated'

type ExtendedUserContext = UserContext & {
  debug?: boolean
}

// Use the ExtendedUserContext type in your code

const styleContextValue = {
  insertCss: () => {
    return () => {}
  },
}

export function clientMount (
  Component: React.ReactElement,
  rootElement: HTMLElement,
  options: FragmentRootOptions
): Root
/**
 * @deprecated since version 0.2.1, use updated clientMount (3 arguments)
 */
export function clientMount (
  Component: React.ReactElement,
  options: ClientMountOptions
): Root
export function clientMount (
  Component: React.ReactElement,
  optionsOrRootElement: HTMLElement | ClientMountOptions,
  renderOptionsOrUndefined: FragmentRootOptions | undefined = undefined
): Root {
  const rootElement =
    optionsOrRootElement instanceof HTMLElement === false
      ? optionsOrRootElement.rootElement
      : optionsOrRootElement

  const userContextValue = createUserContext() as ExtendedUserContext

  const rootOptions = createRootOptions(renderOptionsOrUndefined)
  const hydrateOptions = rootOptions.hydrateOptions ?? {
    identifierPrefix: rootElement.id,
  }

  return hydrateRoot(
    rootElement,
    withErrorBoundary(
      withStrictMode(
        <LoggerProvider loggerOptions={rootOptions.loggerOptions}>
          <UserContextProvider
            value={{ userContext: userContextValue, trackedUserContext: [] }}
          >
            <StyleContextProvider value={styleContextValue}>
              <SwanProvider>
                <div className='swan'>{Component}</div>
              </SwanProvider>
            </StyleContextProvider>
          </UserContextProvider>
        </LoggerProvider>,
        rootOptions.strictMode
      ),
      rootOptions.errorBoundaryOptions
    ),
    hydrateOptions
  )
}
