import React from 'react'
import { ErrorBoundary } from '@vp/swan'

export type ErrorBoundaryOptions =
  | ConstructorParameters<typeof ErrorBoundary>[0]
  | boolean

export const withErrorBoundary = (
  Component: React.ReactElement,
  options: ErrorBoundaryOptions | undefined
) => {
  let errorBoundaryProps: ErrorBoundaryOptions
  if (typeof options === 'object') {
    errorBoundaryProps = options
  } else {
    errorBoundaryProps = options === true ? {} : false
  }

  return errorBoundaryProps
    ? (
      <ErrorBoundary {...errorBoundaryProps}>{Component}</ErrorBoundary>
      )
    : (
        Component
      )
}
