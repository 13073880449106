import { UserContext } from '@vp/ubik-fragment-types'

type CaseInvariant<T extends Record<string, unknown>> = {
  [K in keyof T as K | Lowercase<K & string>]: T[K]
}

export const createInsensitiveProxy = <T extends Record<string, unknown>>(
  data: T
): CaseInvariant<T> => {
  const lowerCaseObject = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key.toLowerCase(), value])
  )

  const proxy = new Proxy(lowerCaseObject, {
    get: (target, prop) => {
      return Reflect.get(target, prop.toString().toLowerCase())
    },
  })

  return proxy as CaseInvariant<T>
}

export const createUserContext = (): CaseInvariant<UserContext> => {
  // render service will embed user context to the html document as a JSON string in a script tag
  const rawData = document.getElementById('ubik-user-context')
  const innerText = rawData?.innerText
  if (!innerText || innerText.length === 0) {
    console.warn(
      'User context not found with element with id "ubik-user-context"'
    )
  }
  try {
    const userContext = JSON.parse(innerText ?? '{}')
    return createInsensitiveProxy(userContext)
  } catch (e) {
    console.warn(
      'User context could not be parsed with element with id "ubik-user-context", returning empty object.'
    )

    // return an empty context object, consumers may have a fallback
    return {} as CaseInvariant<UserContext>
  }
}
